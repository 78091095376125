import React, { useState, useEffect } from "react";
import axios from 'axios';
import TopLogo from './toplogo.png'
import TopBar from './TopBar'
import "./App.css";
import './index.css'
import { supabase } from './supabaseClient'
import Auth from './Auth'
import Account from './Account'
import { Audio } from 'react-loader-spinner'
import { MetaTags } from "react-meta-tags";

export default function App() {
  const [session, setSession] = useState(null)
  const [resultsActive, setResultsActive] = useState(false);
  const queryParams = new URLSearchParams(window.location.search)
  const authToken = queryParams.get("authToken")
  console.log("token", authToken)

  const updateResults = (popularity, artists) => {
    setResultsActive(true);
    console.log(artists);
    updateProfile(popularity, artists);
  }

  async function updateProfile(popularity, artists) {
    try {
      console.log("called", supabase.auth.user())
      const user = supabase.auth.user();
      const updates = {
        id: user.id,
        popularity_score: popularity,
        visual: "gauge",
        fav_artists: artists,
        updated_at: new Date(),
      };
      const updates2 = {
        email: user.email,
        id: user.id,
        visual: "gauge",
      }

      let { error } = await supabase.from('mainstreammeter_profiles').upsert(updates);
      if (error) {
        throw error;
      }
      let { error2 } = await supabase.from('profiles').upsert(updates2);
      console.log("sent update", updates2)
      if (error2) {
        throw error2;
      }
    } catch (error) {
      console.log(error.message);
    }
  }

  useEffect(() => {
    setSession(supabase.auth.session())

    supabase.auth.onAuthStateChange((event, session) => {
      setSession(session)
    })
  }, [])
  if(authToken){
    return (
      <div className="background">
             <TopBar/>

  
        <header className="header1">
          Mainstream Meter
        </header>
        {!resultsActive && !session ?
          <header className="frontstatement">
            How healthy is your music taste?
          </header> : null}
        {!resultsActive && session ?
          <header className="frontstatement">
            Get your musical nutrition breakdown in <br/>
            1 click
          </header> : null}
  
        <body className="container">
          <Account update={updateResults} session={session} wavingToken = {authToken}/>
        </body>
      </div>
    );
  } else
  return (
    <div className="background">
      <TopBar/>
      <header className="header1">
        Mainstream Meter
      </header>
      {!resultsActive && !session ?
        <header className="frontstatement">
        How niche is your music taste?
        </header> : null}
      {!resultsActive && session ?
        <header className="frontstatement">
        Are you as esoteric as you say you are? 😮
        </header> : null}
      <body className="container">
        {!session ? <Auth /> : <Account update={updateResults} key={session.user.id} session={session} />}      </body>
    </div>
  );

}
//
