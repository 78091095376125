import React, { useState, useEffect } from "react";
import "./App.css";
import { useMediaQuery } from 'react-responsive'
import { Audio } from 'react-loader-spinner'
import { supabase } from './supabaseClient'
import hash from "./hash.js";
import GaugeChart from 'react-gauge-chart'
import { FaTiktok, FaInstagram } from 'react-icons/fa'
import { AiOutlineInfoCircle } from 'react-icons/ai'
import { IconContext } from "react-icons";
import ReactTooltip from 'react-tooltip';



export default function Account({ session, update, wavingToken }) {
    //glow, nova, mars
    const mars = "#FF9393";
    const nova = "#FFE27B";
    const water = "#A3D8FF";
    const glow = "#9AFEE0";
    const cosmos = "#B5B4FF";
    const nebula = "#DAAAF6";
    const clientId = "e06a8e4468034056a35e699e61e7b0cb";
    //const redirectUri = "http://localhost:3000";
    const redirectUri = "https://gauge-visual.vercel.app/callback";
    const scopes = [
        "user-top-read",
        "user-read-currently-playing",
        "user-read-playback-state",
    ];
    const axios = require("axios");
    const isDesktopOrLaptop = useMediaQuery({
        query: '(min-device-width: 1224px)'
    })
    const isTabletOrMobile = useMediaQuery({ query: '(max-width: 1224px)' })
    const [loading, setLoading] = useState(false);
    const [topArtists, setTopArtists] = useState([]);
    const [seshToken, setSeshToken] = useState(null);
    const [popularityPercentage, setPopularityPercentage] = useState(null);
    const [numColor, setNumColor] = useState(null);
    const [topArtistsPopularity, setTopArtistsPopularity] = useState([]);
    const getAvg = (artists) => {
        let total = 0;
        let count = artists.length;
        artists.map((artist) => {
            total += artist.popularity;
        })
        return total / count;
    }

    useEffect(() => {
        const getTopArtists = async (token) => {
            const headers = {
                "Authorization": "Bearer " + token
            };
            await axios.get("https://api.spotify.com/v1/me/top/artists", { headers, params: { limit: 10, time_range: "long_term" } }).then((response) => {
                let avg = getAvg(response.data.items)
                let copyarr = response.data.items.slice();
                copyarr.sort((a, b) => (a.popularity > b.popularity) ? -1 : 1)
                setTopArtists(response.data.items)
                setTopArtistsPopularity(copyarr);
                update(Math.floor(avg), JSON.stringify(response.data.items.map((item) => { return item.name })));
                setPopularityPercentage(Math.floor(avg) / 100);
                setNumColor("number" + calcColor(avg));
            })
        }

        //call function
        var mToken = wavingToken?wavingToken:hash.access_token;
        if (mToken) {
            console.log("called")
            setSeshToken(mToken);
            getTopArtists(mToken);
            setLoading(true);

        }

        //reload emojis
        setLoading(false);



    }, [])
    const [tooltip, setTooltip] = useState(false);

    return !loading ? (
        <div className="account-background">
            <div className="getemojiswrap">
                {topArtists.length == 0 && (
                    <div className="prewrap">

                        <a
                            className="btnn btn--loginApp-link"
                            href={`https://accounts.spotify.com/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&scope=${scopes.join(
                                "%20"
                            )}&response_type=token&show_dialog=true`}
                        >Find Out</a>
                    </div>
                )}
                {topArtists.length != 0 && (
                    <div className="resultswrap">
                        <div className="popularitywrapper" >
                            <span className={numColor != null ? numColor : "12"}>{Math.floor(popularityPercentage * 100)}
                                <ReactTooltip type="dark" place="right" />
                                <AiOutlineInfoCircle className="tooltip" data-tip={`This number shows how popular your music taste is! Artists like ${topArtistsPopularity[0].name} are on the mainstream end, whereas ${topArtistsPopularity[topArtistsPopularity.length - 1].name} is more niche :) The lists of artists below the gauge are sorted by the amount of time spent listening to that artist!`} />
                            </span>
                        </div>
                        <div className="totalChartWrap">
                            <span className="left">niche</span>
                            <span className="chartwrap">
                                <GaugeChart id="gauge-chart2"
                                    nrOfLevels={23}
                                    hideText={true}
                                    needleColor={nebula}
                                    needleBaseColor={nebula}
                                    colors={[glow, nova, mars]}
                                    percent={popularityPercentage}
                                    arcWidth={0.3}
                                />
                            </span>
                            <span className="right">mainstream</span>
                        </div>
                        <table className="TablePi">
                            {topArtists.map((artist, index) => {
                                var currCSS = "thArtist" + index
                                var color = "color" + calcColor(artist.popularity);
                                return (
                                    <tr className={currCSS}>
                                        <th className={color}>{artist.name}</th>
                                    </tr>
                                );
                            })}
                        </table>
                        <br></br>
                        <br></br>
                        <IconContext.Provider value={{ color: "#9AFEE0", className: "global-class-name" }}>

                            <div className="bottom-wrap">
                                <a
                                    className="watermarkcss"
                                    href="https://share.koodos.com/nutrition-visual"
                                >
                                    @koodos
                                </a>
                                <br></br>
                                <div className="igtiktokwrap">
                                    <a href="https://share.koodos.com/emojify_koodos_ig">
                                        <FaInstagram className="icons0" border />
                                    </a>
                                    <a href="https://share.koodos.com/emojify_koodos_tiktok">
                                        <FaTiktok className="icons" border />
                                    </a>
                                </div>
                            </div>
                        </IconContext.Provider>
                    </div>
                )}

            </div>
        </div>
    ) : (
        <Audio
            height="100"
            width="100"
            color='grey'
            ariaLabel='loading'
        />
    );

}

const calcColor = (popularity) => {
    if (popularity < 8.3) {
        return 0;
    }
    if (popularity < 16.6) {
        return 1;
    } if (popularity < 24.9) {
        return 2;
    } if (popularity < 33.2) {
        return 3;
    } if (popularity < 41.5) {
        return 4;
    } if (popularity < 49.8) {
        return 5;
    } if (popularity < 58.1) {
        return 6;
    } if (popularity < 66.4) {
        return 7;
    } if (popularity < 74.7) {
        return 8;
    } if (popularity < 83.0) {
        return 9;
    } if (popularity < 91.3) {
        return 10;
    }
    return 11;

}